import { render, staticRenderFns } from "./FooterMini.vue?vue&type=template&id=247c675c&scoped=true&"
import script from "./FooterMini.vue?vue&type=script&lang=ts&"
export * from "./FooterMini.vue?vue&type=script&lang=ts&"
import style0 from "./FooterMini.vue?vue&type=style&index=0&id=247c675c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247c675c",
  null
  
)

export default component.exports