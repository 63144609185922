
















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FooterMini extends Vue {
  isHome = true;
  @Prop() private msg!: string;
}
