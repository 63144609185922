














import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class HeaderMini extends Vue {
  isHome = true;
}
