import { render, staticRenderFns } from "./RegisterV2.vue?vue&type=template&id=342fab09&scoped=true&"
import script from "./RegisterV2.vue?vue&type=script&lang=ts&"
export * from "./RegisterV2.vue?vue&type=script&lang=ts&"
import style0 from "./RegisterV2.vue?vue&type=style&index=0&id=342fab09&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "342fab09",
  null
  
)

export default component.exports