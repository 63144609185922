



























































































import { Form } from "element-ui";
import { Component, Vue } from "vue-property-decorator";
import HeaderMini from "@/components/HeaderMini.vue";
import FooterMini from "@/components/FooterMini.vue";
import { getPhoneCode , hxwReg ,prefixRouter} from "@/api";
// import func from "vue-temp/vue-editor-bridge";

@Component({
  components: {
    HeaderMini,
    FooterMini,
  },
})
export default class Header extends Vue {
  prefixRouter = prefixRouter;
  registerForm = {
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    vcode: "",
    phoneCode: "",
    isAgree: false,
  };

  login2ButtonDisabled = true;
  smsButtonDisable = false;
  smsSecond = 0;
  //smsTimer=null;
  rules = {
    phoneNumber: [
      { required: true, message: "请输入手机号码", trigger: "change" },
      { len: 11, message: "长度需 11 个字符", trigger: "blur" },
      { validator:this.validatePhone , trigger: "blur" },
    ],
    password: [
      { required: true, message: "请输入密码", trigger: "change" },
      { validator: this.validatePassword , trigger: "blur" },
      ],
    confirmPassword: [
      { required: true, message: "请输入确认密码", trigger: "change" },
      { validator: this.validateComfirmPassword, trigger: "blur" },
    ],
    phoneCode: [
      { required: true, message: "请输入手机验证码", trigger: "change" },
      { len: 6, message: "手机验证码位数不正确", trigger: "blur" },
    ],
    // isAgree: [
    //   { validator: this.validateIsAgree, trigger: "blur" },
    // ],
  };


  validateIsAgree(rule: any, value: string, callback: any): void {
    if (this.registerForm.isAgree == true) {
      callback();
    } else {
      callback(new Error("请先同意网站注册协议"));
    }
  }


  validatePassword(rule: any, value: string, callback: any): void {
    if (this.registerForm.password.length < 6 || this.registerForm.password.length > 10) {
      callback(new Error("密码长度在6-10位之间"));
    } else {
      callback();
    }
  }

  validateComfirmPassword(rule: any, value: string, callback: any): void {
    if (!this.registerForm.password && !/\S/.test(value)) {
      callback(new Error("确认密码不能为空"));
    } else if (this.registerForm.password !== value) {
      callback(new Error("确认密码与密码输入不一致"));
    } else {
      callback();
    }
  }

  validatePhone(rule: any, value: string, callback: any) {
    const reg = /^[1][3-9][0-9]{9}$/;
    if (value == "" || value == undefined || value == null) {
      callback();
    } else {
      if (!reg.test(value) && value != "") {
        callback(new Error("请输入正确的电话号码"));
      } else {
        callback();
      }
    }
  }

  validateForm(): void {
    (this.$refs["registerForm"] as Form).validate((valid: boolean) => {
      if (valid) {
        if(this.registerForm.isAgree == true){
          this.submitForm();
        }else{
          this.$message.warning("请先同意网站注册协议");
        }
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }

  getPhoneCode(): void {
    if (
      this.registerForm.phoneNumber &&
      this.registerForm.phoneNumber.length == 11
    ) {
      getPhoneCode(this.registerForm.phoneNumber)
        .then((res) => {
          // console.log(res);
          this.login2ButtonDisabled = false;
          this.smsSecond = 60;
          this.smsButtonDisable = true;
          var smsTimer = setInterval(() => {
            this.smsSecond = this.smsSecond - 1;
            if (this.smsSecond <= 0) {
              this.smsButtonDisable = false;
              clearInterval(smsTimer);
            }
          }, 1000);
        })
        .catch(() => {
          console.log("error test");
        });
    } else {
      this.$message.warning("请输入正确的手机号码");
    }
  }

  submitForm(): void {
    // console.log(this.registerForm);
     hxwReg(this.registerForm)
        .then((res) => {
          // console.log(res.data);
          // console.log(res.data.code);
          if(res.status == 200){
            if(res.data.code == 200){
              this.$message.success("注册成功");
              this.$router.replace("login");
            }else{
              this.$message.error(res.data.msg );
            }
          }else{
            this.$message.error(res.data.statusText );
          }
        })
        .catch(() => {
          this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
          console.log("error test");
        });
  }
}
